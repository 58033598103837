<template>
  <about-us-header />
  <about-back />
  <div class="news" v-if="news">
    <div class="container">
      <div class="news-title">{{ $t("buttons.news") }}</div>
      <div class="news-sub" v-html="news.title"></div>
      <div class="news-date">{{ format_date(news.created_at) }}</div>
      <!-- <div class="news-date">4 марта 2022 г.</div> -->
      <swiper
        :slidesPerView="1"
        :spaceBetween="30"
        :pagination="{
          clickable: true,
        }"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="im in news.images" :key="im">
          <div class="card" v-if="im.path">
            <img :src="cdn + im.path" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="news-text" v-for="mr in news.mores" :key="mr">
        <p v-html="mr.more_description"></p>
      </div>
      <!-- <video/> -->
      <div class="video" v-for="vd in news.videos" :key="vd">
        <iframe
          class="video__card"
          :src="vd.path"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!-- <iframe class="video__card" src="https://www.youtube.com/embed/WEKDgJUTZjo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import videoSlide from "./box/videoSlide.vue";
import aboutUsHeader from "../components/aboutUsHeader.vue";
import AboutBack from "../components/AboutBack.vue";
import axios from "axios";
import { mapState } from "vuex";
// import "./style.css";
import { Autoplay, Pagination } from "swiper";
import moment from "moment";

export default {
  components: {
    Swiper,
    SwiperSlide,
    videoSlide,
    aboutUsHeader,
    AboutBack,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  data() {
    return {
      news: null,
    };
  },
  created() {
    this.getNewsPage();
  },
  methods: {
    applyImageStyles() {
      const images = document.querySelectorAll('.news-text img');
      images.forEach((img) => {
        img.style.width = '100%';
        img.style.height = 'auto';
        img.style.margin = '10px 0'
      });
    },
    getNewsPage() {
      axios
        .get("V1/company-news-by-id", {
          params: {
            company_news_id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.news = response.data.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    format_date(value) {
      const lang = localStorage.getItem('lang');
      if(lang == 'kz') {
        moment.locale('kk');
      }
      else if(lang == 'ru') {
        moment.locale('ru')
      }
      if (value) {
        return moment(String(value)).format('LL');
      }
    },
  },
  updated() {
    this.applyImageStyles();
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style scoped lang="scss">
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.mySwiper {
  padding-bottom: 40px;
}
.news {
  background-color: #efefef;
  padding-bottom: 100px;
  &-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 119.69%;
    color: #373737;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  &-sub {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 119.69%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #373737;
  }
  &-date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 119.69%;
    color: #373737;
    padding-top: 15px;
    padding-bottom: 30px;
  }
  &-text {
    padding-top: 50px;
    padding-bottom: 30px;
    p {
      margin: 10px 0;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 130%;
      color: #373737;
      word-spacing: 10px;
    }
  }
  @include mobile() {
    padding-bottom: 40px;
    &-title {
      font-size: 18px;
      line-height: 22px;
      padding-top: 20px;
      padding-bottom: 20px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-sub {
      font-size: 18px;
      line-height: 22px;
    }
    &-date {
      font-size: 14px;
      line-height: 16px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    &-text {
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @include tablet() {
    padding-bottom: 40px;
    &-title {
      font-size: 18px;
      line-height: 22px;
      padding-top: 20px;
      padding-bottom: 20px;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 27.97%,
        #1b27ff 48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-sub {
      font-size: 18px;
      line-height: 22px;
    }
    &-date {
      font-size: 14px;
      line-height: 16px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    &-text {
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @include tablet-1024() {
    padding-bottom: 60px;
    &-title {
      font-size: 18px;
      line-height: 22px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-sub {
      font-size: 18px;
      line-height: 22px;
    }
    &-date {
      font-size: 14px;
      line-height: 16px;
      padding-top: 5px;
      padding-bottom: 10px;
    }
    &-text {
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @include desktop() {
    &-title {
      font-size: 22px;
      line-height: 25px;
      padding-top: 30px;
      padding-bottom: 25px;
    }
    &-sub {
      font-size: 24px;
      line-height: 26px;
    }
    &-text {
      padding-top: 25px;
      padding-bottom: 15px;
    }
  }
}
.card {
  background-color: transparent;
  border: none;
  img {
    width: 86%;
    max-height: 515px;
    margin: 0 auto;
    object-fit: cover;
  }
  @include mobile() {
    img {
      width: 100%;
    }
  }
  @include tablet() {
    img {
      width: 100%;
    }
  }
}
.video {
  // padding-top: 50px;
  iframe {
    padding-top: 30px;
  }
  &__card {
    aspect-ratio: 16/9;
    height: 100%;
    width: 100%;
    // border-radius: 50px;
  }
}
</style>
